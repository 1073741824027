import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TodayIcon from '@material-ui/icons/Today';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  fontSize: '1.75rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    fontWeight: 400,
  },
};
theme.typography.h2 = {
  fontSize: '1.5rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
};
const Bline = styled.hr`
  width: 70px;
  border-color: #96cefd;
  border-width: thin;
  `
  const Date = styled.span`
  color: #fff;
  background-color: green;
  padding: 8px 12px;
  `


export default function Template({data})
 {
  const { markdownRemark } = data;// data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { featuredImage } = frontmatter;
  const ogImagePath = featuredImage && featuredImage.childImageSharp.fixed.src;
  return (
    <Layout>
    <SEO title= {frontmatter.title+" | blog.chennaihost.com"} description={frontmatter.title} image={ogImagePath} pathname={frontmatter.path} article />

    <ThemeProvider theme={theme}>
      <Container>
        <Box mt={1}>
<Breadcrumbs aria-label="breadcrumb">
  <Link color="inherit" to="/">
    Home
  </Link>
  <Link color="inherit" to={frontmatter.link}>
  {frontmatter.category}
  </Link>

</Breadcrumbs>
</Box>

<Box pb={2} mt={2}>
      <Grid container spacing={2} className="boxshad"  justify="center" alignItems="center">
      <Grid item xs={12}>
      
        <Typography  gutterBottom variant="h1" align="center">
        {frontmatter.title}
       </Typography>
       <Bline/>
        <Typography align="right"> <Date><TodayIcon style={{ position:'relative', top:5 }}/> {frontmatter.date}</Date></Typography>
        </Grid>
        <Grid item xs={12} md={10}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
    </Grid>
    </Grid>
    </Box>

    </Container>
    </ThemeProvider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        facebook
        category
        link
        featuredImage { 
          childImageSharp {
            fixed {
              src
            }
          }
        }
        
      }
    }
  }
`
